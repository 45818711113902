import "./App.scss";
import { ReactComponent as ColorLine } from "../src/assets/kreski.svg";
import VideoPlaceHolder from "../src/assets/video-placeholder.png";
import Video from "../src/assets/video.mp4";

function App() {
  const requestFullscreen = function (ele) {
    if (ele.requestFullscreen) {
      ele.requestFullscreen();
    } else if (ele.webkitRequestFullscreen) {
      ele.current.webkitRequestFullscreen();
    } else if (ele.mozRequestFullScreen) {
      ele.mozRequestFullScreen();
    } else if (ele.msRequestFullscreen) {
      ele.msRequestFullscreen();
    } else {
      console.log("Fullscreen API is not supported.");
    }
  };

  const handleFullScreen = (ele) => {
    console.log(ele);
    requestFullscreen(ele);
  };

  return (
    <div className="app">
      <ColorLine className="color-line" preserveAspectRatio="none" />
      <div className="page">
        <section>
          <h1 className="app-title">O programie BAT&nbsp;Partners</h1>
          <p>
            Sprawdź wszystkie korzyści płynące z BAT Partners i&nbsp;wykorzystaj
            swój potencjał w&nbsp;pełni.
          </p>
          <div className="how-it-works__video-container">
            <video
              className="how-it-works__video"
              controlsList="nodownload"
              controls
              poster={VideoPlaceHolder}
              onPlay={(vid) => {
                handleFullScreen(vid.target);
              }}
              playsInline
            >
              <source src={Video} type="video/mp4" />
            </video>
          </div>
          <div className="how-it-works__cta-wrapper">
            <a className="btn" href="https://batpartners.pl/register">
              Zarejestruj konto
            </a>
          </div>
        </section>
      </div>
    </div>
  );
}

export default App;
